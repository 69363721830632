<template>
  <div>
    <base-table ref="tableRef" :columnsData="columns" rowKey="id" :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold text-black">发货工厂配置</h4>
        </div>
      </template>
      <template #operation="{record}">
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerEdit(record)">编辑</a-button>
      </template>
    </base-table>
    <factory-modal v-if="isModalState.show" :showState.sync="isModalState" />
  </div>
</template>

<script>
import FactoryModal from "./components/factory-modal.vue"
import { getFactoryAreaList } from "@/api/warehouse"

export default {
  components: {
    FactoryModal,
  },
  data() {
    return {
      columns: [ 
        {
          title: '工厂仓库名',
          dataIndex: 'name',
          align: 'center',
          width: 150
        },
        {
          title: '对应的收货区域',
          dataIndex: 'delivery_region',
        },
        {
          title: '实体卡城市码',
          dataIndex: 'stored_value_card_city_code',
          align: 'center',
          width: 110,
        },
        {
          title: '实体卡门店码',
          dataIndex: 'stored_value_card_outlets_code',
          align: 'center',
          width: 110,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          width: 80,
          slots: {
            customRender: 'operation'
          }
        },
      ],
      tableData: [],

      isModalState: {
        show: false,
        type: "add",
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { data, code } = await getFactoryAreaList()
      if (code === 0) {
        this.tableData = data.list
      }
    },

    handlerEdit(row) {
      this.isModalState = {
        show: true,
        type: "edit",
        data: row,
      }
    },


  },
}
</script>
