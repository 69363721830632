<template>
  <a-modal
    :visible.sync="showState.show"
    title="工厂仓库的收货地区"
    destroyOnClose
    width="700px"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="addForm"
      >
        <a-form-model-item prop="name" label="工厂仓库名">
          {{addForm.name}}
        </a-form-model-item>


        <a-form-model-item prop="delivery_region_data" label="覆盖区域">
          <region-tree-select @getNodes="getSelectNodes" :value.sync="region_tree_data" />
        </a-form-model-item>

        <a-form-model-item prop="stored_value_card_city_code" label="实体卡配置">
          <div>
            <span class="mr-1">城市码</span>
            <a-input v-model="addForm.stored_value_card_city_code"
                       placeholder="城市码"
                       style="width:120px;" />
            <span class="ml-4 mr-1">门店码</span>
            <a-input v-model="addForm.stored_value_card_outlets_code"
                       placeholder="门店码"
                       style="width:120px;" />
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import RegionTreeSelect from "@/components/region-tree-select"

import { updateFactory } from "@/api/warehouse"

export default {
  components: { RegionTreeSelect },
  props: {
    showState: {
      type: Object,
    },
  },
  data() {
    return {
      wrapperCol: { span: 20 },
      labelCol: { span: 4},
      addForm: {
        id:   this.showState.data.id,
        name: this.showState.data.name,
        stored_value_card_city_code:    this.showState.data.stored_value_card_city_code,
        stored_value_card_outlets_code: this.showState.data.stored_value_card_outlets_code,
        delivery_region_data: "",
      },
      region_tree_data: [],
    }
  },
  mounted() {
    this.initRegionsData()
  },
  methods: {
    async initRegionsData() {
      
      const treeData = []
      this.showState.data.delivery_region_adcode.map((province) => {
        const provinceData = {
          type: 1,
          adcode: province.province_adcode,
          selectChildren: [],
        }
        treeData.push(provinceData)

        if (province.city_list.length > 0) {
          province.city_list.map((city) => {
            const cityData = {
              type: 2,
              adcode: city.city_adcode,
              selectChildren: []
            }
            provinceData.selectChildren.push(cityData)
            if (city.area_list.length > 0) {
              city.area_list.map((area) => {
                const areaData = {
                  type: 3,
                  adcode: area.area_adcode,
                  selectChildren: []
                }
                cityData.selectChildren.push(areaData)
              })
            }
          })
        }
      })
      this.region_tree_data = treeData

      // const regions = []
      // this.showState.data.delivery_region_adcode.map((item) => {
      //   if (item.city_list.length > 0) {
      //     item.city_list.map((el) => {
      //       if (el.area_list.length > 0) {
      //         el.area_list.map((it) => {
      //           regions.push({
      //             adcode: it.area_adcode,
      //             type: 3,
      //           })
      //         })
      //       }else{
      //         regions.push({
      //           adcode: el.city_adcode,
      //           type: 2,
      //         })
      //       }
      //     })
      //   }else{
      //     regions.push({
      //       adcode: item.province_adcode,
      //       type: 1,
      //     })
      //   }
      // })
      // this.addForm.delivery_region_data = regions
    },

    // 保存
    handleOk() {
      if(!this.addForm.stored_value_card_city_code){
        this.$message.info("请填写实体卡城市码")
        return
      }
      if(!this.addForm.stored_value_card_outlets_code){
        this.$message.info("请填写实体卡门店码")
        return
      }

      const params = this.addForm
      // if(typeof this.addForm.delivery_region_data === 'string'){
      //   this.addForm.delivery_region_data = JSON.parse(this.addForm.delivery_region_data)
      // }
      // params.delivery_region_data = JSON.stringify(
      //   this.addForm.delivery_region_data
      // )
      params.delivery_region_data = JSON.stringify(this.region_tree_data)

      updateFactory(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("编辑成功")
          this.showState.show = false
          this.$parent.initData()
        }
      })
    },

    getSelectNodes(nodes){
      this.region_tree_data = nodes
      // this.addForm.delivery_region_data = nodes
    },
    
    handlerCancel() {
      this.showState.show = false
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>